import Vue from "vue";
import App from "./App.vue";

// Buefy
import Buefy from "buefy";
Vue.use(Buefy);
import "./assets/css/main.scss";

// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTshirt } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitch,
  faFacebook,
  faSoundcloud,
  faSpotify,
  faYoutube,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faTshirt,
  faTwitch,
  faFacebook,
  faSoundcloud,
  faSpotify,
  faYoutube,
  faInstagram
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount("#app");
