






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SocialIcon extends Vue {
  @Prop() private icon!: string;
  @Prop() private url!: string;

  get isBrand() {
    return !["tshirt"].includes(this.icon);
  }
}
