












import { Component, Vue } from "vue-property-decorator";
import SocialIcon from "./SocialIcon.vue";

@Component({
  components: {
    SocialIcon
  }
})
export default class SocialBranding extends Vue {
  private readonly socials = [
    {
      icon: "spotify",
      url: "https://open.spotify.com/artist/5UmF735e1VLKtDRl2ZohyK"
    },
    {
      icon: "soundcloud",
      url: "https://soundcloud.com/drd0wney"
    },
    {
      icon: "tshirt",
      url: "https://merch.drd0wney.com/"
    },
    {
      icon: "facebook",
      url: "https://www.facebook.com/DrD0WNEY"
    },
    {
      icon: "twitch",
      url: "https://www.twitch.tv/drd0wney"
    },
    {
      icon: "youtube",
      url: "https://www.youtube.com/user/DrD0WNEY"
    },
    {
      icon: "instagram",
      url: "https://www.instagram.com/drd0wney/"
    }
  ];
}
